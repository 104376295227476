<template lang="pug">
CanvasModal#designer-element-upload-modal(
  :is-show="true"
  :max-width="600"
  :title="modalTitle"
  @closeOverlay="closeDesignerElementUploadModal"
)
  template(
    v-if="showForm"
  )
    .forms
      .form-input
        .label Featured
        .input.toggle-switch
          BaseSwitch(
            name='isShow',
            :is-checked='formData.isFeatured',
            @change='formData.isFeatured = !formData.isFeatured'
          )
      .form-input
        .label Name
        .input
          BaseInputField(
            type="text"
            v-model="formData.name"
          )

      .form-input
        .label Menu
        .input
          MultiSelect.element-menu.multi-select(
            name='element_menu_id',
            v-model='formData.menuId',
            label='name',
            track-by='value',
            :options='menuOptions',
            :multiple='true',
            :taggable='true',
            :searchable='true'
          )

      .form-input
        .label Pack
        .input
          MultiSelect.element-category.multi-select(
            v-model='formData.categoryId',
            label='name',
            track-by='value',
            :options='packOptions',
            :multiple='true',
            :taggable='true',
            :searchable='true'
          )

      .form-input
        .label(
          :class="{ 'tags-wrapper': isImageScanning }"
        )
          span Tags
          BasePreloader(
            v-if="isImageScanning"
            :width="40"
            :type="4"
          )

        .input
          input-tag(
            v-model="formData.tags"
            :before-adding="modifyTag"
          )

      .radio-input__list
        label.radio-input
          input.input(
            name='type',
            type='radio',
            :checked='formData.type === "static"',
            v-model='formData.type',
            value='static'
          )
          p Static
        label.radio-input
          input.input(
            name='type',
            type='radio',
            :checked='formData.type === "animated"',
            v-model='formData.type',
            value='animated'
          )
          p Animated

      .radio-input__sublist(v-if='formData.type === "animated"')
        .list
          label.radio-input
            input.input(
              name='animation_type',
              type='radio',
              :checked='formData.animationType === "none"',
              v-model='formData.animationType',
              value='none'
            )
            p None
          label.radio-input
            input.input(
              name='animation_type',
              type='radio',
              :checked='formData.animationType === "loop"',
              v-model='formData.animationType',
              value='loop'
            )
            p Loop
          label.radio-input
            input.input(
              name='animation_type',
              type='radio',
              :checked='formData.animationType === "freeze"',
              v-model='formData.animationType',
              value='freeze'
            )
            p Freeze

      .preview-frame__input(v-if='formData.type === "animated"')
        .form-input
          .label Preview Frame
          .input
            BaseInputField(
              name='preview',
              type='number'
              v-model='formData.preview',
            )

      .form-input(
        v-show="isBackgrounds"
      )
        .label BG Color
        .input
          BaseColorpicker(
            :color="formData.bgColor"
            @set="updateBgColor"
          )

      .form-input
        .label Custom thumbnail
        .input
          input(
            name='file'
            ref='customThumbnail'
            type='file'
            @change='uploadCustomThumbnail',
            accept="image/*"
          )

      BaseButton.save-button(
        :is-success='true',
        :is-tall='true'
        :disabled="isUploading || isImageScanning"
        @click="submitForm"
      )
        BasePreloader(
          v-if="isUploading"
          :width="30"
          :type="4"
        )

        template(v-else) Save

  template(
    v-else
  )
    .button-wrapper
      BaseButton(
        v-if="showOwnLibraryButton"
        :is-primary="true"
        @click="$emit('choose-option', designerElementUploadOptions.OWN)"
      ) + Own Library
      BaseButton(
        v-if="showElementsButton"
        :is-primary="true"
        @click="$emit('choose-option', designerElementUploadOptions.ELEMENTS)"
      ) + Elements
      BaseButton(
        v-if="showBackgroundsButton"
        :is-primary="true"
        @click="$emit('choose-option', designerElementUploadOptions.BACKGROUNDS)"
      ) + Background
</template>

<script>

/*
custom dropdown validations
| type  | table       | menu     |
|-------|-------------|----------|
| .mp4  | backgrounds | Videos   |
| .webm | elements    | Graphics |
| .webm | elements    | Effects  |
| .webm | elements    | Overlays |


| type  | table       | menu     |
|-------|-------------|----------|
| .jpg  | backgrounds | Icons    |
| .png  | backgrounds | Icons    |
| .jpg  | elements    | Graphics |
| .png  | elements    | Graphics |
| .svg  | elements    | Graphics |
| .svg  | elements    | Masks    |
| .svg  | elements    | Borders  |
*/
import {
  mapState, mapMutations, mapGetters, mapActions,
} from 'vuex';

import InputTag from 'vue-input-tag';
import MultiSelect from 'vue-multiselect';
import helperMixin from '@/components/mixins/helper-mixins';

import { designerElementUploadOptions } from '@/assets/scripts/enums';
import { mapDropDownValueBeforeSave } from '@/assets/scripts/utilities';

export default {
  name: 'DesignerElementUploadModal',
  components: { InputTag, MultiSelect },
  props: {
    isUploading: { type: Boolean, default: false },
    showOwnLibraryButton: { type: Boolean, default: true },
    showElementsButton: { type: Boolean, default: true },
    showBackgroundsButton: { type: Boolean, default: true },
    isImageScanning: { type: Boolean, default: false },
    scannedImageTags: { type: Array, default: () => [] },
    fileExtension: { type: String, default: '' },
  },
  mixins: [helperMixin],
  data() {
    return {
      formData: {
        type: 'static',
        animationType: 'none',
        name: '',
        menuId: [],
        categoryId: [],
        tags: [],
        preview: 0,
        // element_menu_id: [],
        // element_category_id: [],
        // background_menu_id: [],
        // background_category_id: [],
        // file: '', // will be added in file upload
        previewUrl: '',
        isFeatured: false,
        bgColor: '#FFF',
        customThumbnail: '',
      },
      designerElementUploadOptions,
    };
  },
  computed: {
    ...mapState([
      'selectedDesignerElementUploadOption',
    ]),
    ...mapState('assetsSidebar', [
      'generalCategories',
      'sidebarMenus',
    ]),
    ...mapGetters('assetsSidebar', [
      'hasGeneralCategories',
    ]),
    isElements() {
      return this.selectedDesignerElementUploadOption === designerElementUploadOptions.ELEMENTS;
    },
    isBackgrounds() {
      return this.selectedDesignerElementUploadOption === designerElementUploadOptions.BACKGROUNDS;
    },
    isVideoFile() {
      return this.fileExtension === 'mp4' || this.fileExtension === 'mov' || this.fileExtension === 'webm';
    },
    menuOptions() {
      try {
        return this.sidebarMenus[this.selectedDesignerElementUploadOption].filter((option) => {
          // console.log('categoryMenuOptions', option);
          if (this.isBackgrounds) {
            if (this.isVideoFile) {
              return option.slug === 'videos' ? option : '';
            }
            return option.slug !== 'videos' ? option : '';
          }

          if (this.isElements) {
            if (this.isVideoFile) {
              // video file would be .webm || .mov
              return option.slug === 'graphics' ? option : '';
            }
          }
          return option;
        }).map(option => ({
          name: option.name,
          value: JSON.stringify(option.id),
        }));
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    packOptions() {
      console.log(this.generalCategories);
      try {
        if (!this.hasGeneralCategories) return [];
        return Object.values(this.generalCategories.byId).map(category => ({
          name: category.name,
          value: JSON.stringify(category.id), // need to be string for saving in database
        }));
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    modalTitle() {
      let text = 'Upload';
      if (this.selectedDesignerElementUploadOption !== designerElementUploadOptions.OWN) {
        text += ` ${this.selectedDesignerElementUploadOption}`;
      }
      return text;
    },
    showForm() {
      return this.selectedDesignerElementUploadOption && this.selectedDesignerElementUploadOption !== designerElementUploadOptions.OWN;
    },
  },
  methods: {
    ...mapMutations(['setShowDesignerElementUploadModal', 'setSelectedDesignerElementUploadOption']),
    ...mapActions(['closeDesignerElementUploadModal']),
    modifyTag(tag) {
      // console.log('modifyTag', tag);
      try {
        return tag.split(' ').join('');
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    submitForm() {
      if (this.isUploading) return;
      if (this.isImageScanning) {
        this.alertWarn('', 'Image scanning still on process, please wait.', 5000, 'TopCenterNotif');
        return;
      }
      const formData = {
        type: this.formData.type,
        animation_type: this.formData.animationType,
        name: this.formData.name,
        tags: this.formData.tags.join(','),
        preview: this.formData.preview,
        // file: '', // will be added in file upload
        preview_url: this.formData.previewUrl,
        is_featured: this.formData.isFeatured,
      };

      console.log(this.designerElementUploadOption);

      if (this.formData.customThumbnail) {
        formData.custom_thumbnail = this.formData.customThumbnail;
      }

      if (this.isElements) {
        formData.element_menu_id = mapDropDownValueBeforeSave(this.formData.menuId);
        formData.element_category_id = mapDropDownValueBeforeSave(this.formData.categoryId);
      }

      if (this.isBackgrounds) {
        formData.background_menu_id = mapDropDownValueBeforeSave(this.formData.menuId);
        // for background videos background_category not required
        formData.background_category_id = mapDropDownValueBeforeSave(this.formData.categoryId);

        const menuVideoId = this.sidebarMenus[designerElementUploadOptions.BACKGROUNDS].filter(option => option.slug === 'videos');

        // eslint-disable-next-line
        if (formData.background_menu_id[0] == menuVideoId[0].id && formData.background_category_id.length === 0) {
          // remove this form to skip validation
          delete formData.background_category_id;
        }

        formData.bg_color = this.formData.bgColor;
      }
      this.$emit('submit', formData);
    },
    updateBgColor(color) {
      // console.log('updateBgColor', color);
      this.formData.bgColor = color.hex;
    },
    uploadCustomThumbnail(event) {
      // eslint-disable-next-line
      this.formData.customThumbnail = event.target.files[0];
    },
  },
  watch: {
    scannedImageTags() {
      console.log('scannedImageTags', this.scannedImageTags);
      const tmpTags = this.formData.tags;
      this.formData.tags = [...new Set(tmpTags.concat(this.scannedImageTags))];
      // console.log(this.formData.tags);
    },
  },
};
</script>

<style lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';
#designer-element-upload-modal {
  .button-wrapper {
    display: flex;
    justify-content: space-evenly;

    button {
      padding: 40px 20px;
      width: 170px;
      font-size: 1em
    }
  }
  .forms {
    .form-input {
      display: flex;
      min-height: 70px;
      align-items: center;

      .label {
        min-width: 90px;
        text-align: left;
      }

      .tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .input {
        width: 100%;

        .vue-input-tag-wrapper {
          .input-tag {
            background: $green;
            color: #fff;
            border: none;
            padding: 5px 10px;
            border-radius: 5px;
          }
        }

        &.toggle-switch {
          display: flex;
        }

        .switch {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          cursor: pointer;
          input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked {
              +.slider {
                background-color: $green;
              }
            }
          }
          .slider {
            margin: 0;
            cursor: pointer;
            width: 40px;
            height: 22px;
            background-color: $grey;
            transition: .4s;
            position: relative;
            display: inline-block;

            &.round {
              border-radius: 34px;
            }
          }
        }
      }
    }

    .radio-input__list {
      display: flex;
      align-items: center;

      .radio-input {
        display: flex;
        align-items: center;
        margin-right: 20px;

        p {
          margin-left: 10px;
        }
      }
    }

    .radio-input__sublist,
    .preview-frame__input {
      margin-top: 20px;
      margin-left: 20px;
      padding-left: 20px;
      border-left: 2px solid #ddd;

      .list {
        display: flex;
      }

      .radio-input {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .input {
          margin-right: 20px;
        }
      }
    }

    .preview-frame__input {
      .input-field__wrapper{
        max-width: 80px;
        input {
          text-align: center;
        }
      }
    }

    .save-button {
      display: flex;
      margin-top: 20px;
    }
  }
}
</style>
